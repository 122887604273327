import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TopSection from "../components/TopSection"
import Form from "../components/Form"
import Story from "../section/Story"
import Team from "../section/Team"

import profileImage from "../images/vector_profile.png"

const About = () => (
    <Layout>
        <Seo title="About us" />
        <TopSection image={profileImage} imageWidth="11rem" hasVideo>
            <h1>Our mission</h1>
            <p style={{ marginBottom: "1rem" }}>
                We are on a mission to foster engagement between female golfers
                - no matter location, professional endeavour or level of play.
            </p>
            <p>
                We are creating a unique platform where women get connected,
                inspired and educated about golf and life through each other and
                the actors in the golf industry. A safe place where female
                golfers values, needs and voices are heard. Our ambition lies in
                working towards sustainable solution for women, the golf
                industry and the 17 global sustainability goals.
            </p>
        </TopSection>
        <Story />
        <Team />
        <Form />
    </Layout>
)

export default About
