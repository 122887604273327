import React from "react"
import "./style.css"

const SmallCard = ({
    imageUrl,
    title,
    description,
    type,
    children,
    onCardClick,
    style
}) => {
    const getClassName = () =>
        type !== "profile" ? "small-card alignCenter" : "small-card"

    return (
        <div className={getClassName()} style={style}>
            {type !== "profile" ? (
                <div onClick={onCardClick} className="small-card--button">
                    <div
                        className="small-card__image"
                        style={{ backgroundImage: `url(${imageUrl})` }}
                    />
                    <h2 className="small-card__title">{title}</h2>
                    <p className="small-card__description">{description}</p>
                </div>
            ) : (
                <>
                    <div
                        className="small-card__image--profile"
                        style={{ backgroundImage: `url(${imageUrl})` }}
                    />
                    {children}
                </>
            )}
        </div>
    )
}

export default SmallCard
