import React, { useState } from "react"
import "./style.css"
import Button from "../Button"

import Input from "../Input"

const Form = () => {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [error, setError] = useState(null)

    const initialState = {
        firstName: "",
        lastName: "",
        subject: "",
        comments: ""
    }

    const [formData, setFormData] = useState(initialState)

    const requiredFields = ["firstName", "lastName", "subject", "comments"]

    const encode = data => {
        return Object.keys(data)
            .map(
                key =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(data[key])
            )
            .join("&")
    }

    const setData = (type, value) => {
        const obj = { ...formData }
        obj[type] = value
        setFormData(obj)
    }

    const validateForm = () => {
        let isValid = true
        for (const field of requiredFields) {
            if (!formData[field]) {
                isValid = false
            }
        }
        return isValid
    }

    const isValid = () => {
        setIsSubmitted(true)
        setFormData(initialState)
        setTimeout(() => {
            setError(false)
            setIsSubmitted(false)
        }, 3000)
    }

    const onSubmit = e => {
        e.preventDefault()
        setIsSubmitted(false)
        setError(null)
        if (validateForm()) {
            fetch("/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: encode({ "form-name": "get_in_touch", ...formData })
            })
                .then(() => isValid())
                .catch(error => alert(error))
        } else {
            setError("You must fill required fields")
            setTimeout(() => setError(null), 3000)
        }
    }

    return (
        <div className="aray__form__wrapper">
            <h2 className="aray__form__title">Want to get in touch?</h2>
            <form
                className="aray__form__container"
                method="post"
                name="get_in_touch"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={onSubmit}
            >
                <input type="hidden" name="form-name" value="get_in_touch" />
                <div className="aray__form__name">
                    <Input
                        placeholder="First name *"
                        name="firstName"
                        value={formData.firstName}
                        onChange={({ target }) =>
                            setData("firstName", target.value)
                        }
                    />
                    <Input
                        placeholder="Last name *"
                        name="lastName"
                        value={formData.lastName}
                        onChange={({ target }) =>
                            setData("lastName", target.value)
                        }
                    />
                </div>
                <Input
                    placeholder="Subject *"
                    name="subject"
                    value={formData.subject}
                    onChange={({ target }) => setData("subject", target.value)}
                />
                <Input
                    placeholder="What do you have in mind? *"
                    name="comments"
                    value={formData.comments}
                    onChange={({ target }) => setData("comments", target.value)}
                    isTextArea
                />
                <div className="button--wrapper">
                    <Button type="submit" onClick={onSubmit}>
                        SUBMIT
                    </Button>
                </div>
            </form>

            {error && <h3 className="error-message">{error}</h3>}
            {isSubmitted && (
                <h3 className="success-registration">
                    Success! Information was sent.
                </h3>
            )}
        </div>
    )
}

export default Form
