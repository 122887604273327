import React from "react"
import "./style.css"

import SmallCard from "../../components/SmallCard"
import Frame from "../../components/Frame"

const Team = () => {
    return (
        <Frame frame={3}>
            <div className="team__container">
                <h2>Founded, designed and developed</h2>
                <h2>BY women in golf FOR women in golf</h2>
                <div className="team__container__cards">
                    <div className="team__container__cards--wrapper">
                        <SmallCard type="profile" imageUrl="./pic/caroline.jpg">
                            <h3>Caroline Blixt</h3>
                            <p className="team__title">Founder & CEO</p>
                            <p>
                                Player and golf entrepreneur with 10+ years of
                                experience working in the golf industry on an
                                international scale.
                            </p>
                        </SmallCard>
                    </div>
                    <div className="team__container__cards--wrapper">
                        <SmallCard
                            type="profile"
                            imageUrl="./pic/hedy_founders.png"
                        >
                            <h3>Hedylity Technology</h3>
                            <p className="team__title">Founder & Techpartner</p>
                            <p>
                                A tech innovation agency co-investing in
                                startups connected to the 17 global goals,
                                founded by Emelie Meurk Demerud and Hanna
                                Moisander.
                            </p>
                        </SmallCard>
                    </div>
                </div>
            </div>
        </Frame>
    )
}

export default Team
