import React from "react"
import "./style.css"

const Story = () => {
    return (
        <div className="advantages__container">
            <h1>Our story</h1>
            <div className="advantages__container--section">
                <div className="advantages__container--column">
                    <h2 className="advantages__container--title">Caroline</h2>
                    <p>
                        Sport and golf has always been a big part of my life
                        growing up. My father, who introduced us all to golf,
                        did his best to get me into the game early as well, but
                        I never got caught, why? I didn’t have any of my
                        girlfriends to play with. Easy as that! My own golf
                        journey started in my twenties. A bad back injury
                        stopped me from playing and fulfilling my dream of
                        playing professional indoor hockey. Determined to get
                        back into sports after the injury, I embarked on a golf
                        journey of my own. Now, years later, playing and working
                        in the golf industry, I have golf friends all over the
                        world and a big love and thankfulness to this game and
                        what it has given me.
                    </p>
                    <p>
                        It doesn't take long, until you realize the enormous
                        void of female representation playing, in the pro shop,
                        on corporate outings, teaching, everywhere. As such, I
                        began to feel a strong sense of responsibility to propel
                        change.
                    </p>
                </div>
                <div className="advantages__container--column">
                    <h2 className="advantages__container--title">Emelie</h2>
                    <p>
                        I grew up on the golf course, getting around on my
                        father’s golf bag when my legs hurt. Getting a bit older
                        I learned the game and found the passion. There is no
                        greater feeling than to hit the ball exactly the way you
                        intended to. The golf course became a second home for me
                        growing up and I have got friends for life through this
                        sport.
                    </p>

                    <p>
                        I realize now, as a serial entrepreneur what benefits I
                        have gotten from golf. The mindset, psychological ease
                        in handling new situations and mistakes, take a step out
                        of it and continue with the same focus as before.
                    </p>
                    <p>
                        Together with Hanna Moisander I have founded the tech
                        innovation agency, Hedylity Technology. We have invested
                        in, and jointly founded, ARAYSociety together with
                        Caroline Blixt because we believe that empowering female
                        golf will have a huge impact on health, inclusion,
                        norms, structures and the business world as we know it.
                        Let’s do this!
                    </p>
                </div>
            </div>
            <div className="advantages--quote-section">
                <cite>
                    "ARAY Society was born out of the desire to welcome more
                    women into golf, while highlighting the next-generation of
                    the golf industry as more inclusive and equal. ARAY Society
                    provides a new perspective of female golf to the golf
                    industry."
                </cite>
            </div>
        </div>
    )
}

export default Story
